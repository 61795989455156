<template>
  <div style="background-color: white !important;">
    <v-container class="pa-12" style="max-width: 1300px !important;">

      <edit-article-component :id="$route.params.id"></edit-article-component>

    </v-container>
  </div>
</template>

<script>
import EditArticleComponent from "./components/EditArticleComponent";

export default {
  name: "PublishArticlePage",
  components: {
    EditArticleComponent
  }
}
</script>

<style scoped>

</style>
